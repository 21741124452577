(function () {
    'use strict';

    var monthTableControl = {
    	restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/calendar/controls/month-table.html',
        controllerAs: 'vm',
        bindings: {
            widget: "=",
            year:'<',
            month:'<',
            week:'<',
            scale:'<',
            width:'<',
            height:'<',
            thumbnail:'<',
            update:'=',
            isMin:'<',
        },

        controller: ['$scope', '$timeout','CalendarService','$rootScope', function ($scope, $timeout,CalendarService,$rootScope) {
            var vm = this;
            vm.data = {
                cols: 7,
                rows: 5,
                clientWidth: 426,
                clientHeight: 396,
                headCellWidth: 60,
                headCellHeight: 30,
                headCellMargin: 0,
                dateCellWidth: 60,
                dateCellHeight: 60,
                dateCellMargin: 0,
                heads: [],
                dates: []
            };

            vm.$onInit = function() {
                if(vm.widget){
                    dataInfo()
                    vm.widget.ctrl.displayType = vm.widget.displayType || "C7";
                    vm.widget.ctrl.width = vm.widget.width || 426;
                    vm.widget.ctrl.height = vm.widget.height || 396;
                    vm.widget.ctrl.weekLayout = orMockWeekLayout(vm.widget.weekLayout);
                    vm.widget.ctrl.dateLayout = orMockDateLayout(vm.widget.dateLayout);
                    queryMonthdaysDataset(function () {
                        reconstructTable();
                    })
                }
            }
            // 每當 one-way binding 更新時，changesObj 就是改變前後的差異
            vm.$onChanges = function(data) {
                if(vm.widget){
                    if(!vm.widget.ctrl){
                        vm.widget.ctrl={}
                    }
                    if(data.year&&data.year.currentValue&&data.year.previousValue>0){vm.year = data.year.currentValue; vm.widget.obj=null;}
                    if(data.month&&data.month.currentValue&&data.month.previousValue>0){vm.month = data.month.currentValue; vm.widget.obj=null;}
                    if(data.week&&data.week.currentValue){vm.week = data.week.currentValue}
                    if(data.widget&&data.widget.currentValue){vm.widget = data.widget.currentValue}

                    if(data.width&&data.width.currentValue&&data.width.previousValue>0&&data.height&&data.height.currentValue&&data.height.previousValue>0){
                        vm.widget.ctrl.width = data.width.currentValue;
                        vm.widget.ctrl.height = data.height.currentValue;
                    }
                    queryMonthdaysDataset(function () {
                        reconstructTable();
                    })
                }
            }
            vm.$postLink = function () {

            }
            vm.$doCheck = function() {
                // deep watch
            }
            vm.$onDestroy = function () {

            }

            function dataInfo() {
                if(vm.widget.geometry){
                    vm.widget.width = vm.widget.geometry.width;
                    vm.widget.height = vm.widget.geometry.height;
                    if(vm.widget.weekLayout.geometry){
                        vm.widget.weekLayout.width = vm.widget.weekLayout.geometry.width;
                        vm.widget.weekLayout.height = vm.widget.weekLayout.geometry.height;
                    }
                    if(vm.widget.weekLayout.items&&vm.widget.weekLayout.items.item){
                        vm.widget.weekLayout.items = vm.widget.weekLayout.items.item;
                        vm.widget.weekLayout.items.forEach(function (weekLayout) {
                            weekLayout.w = weekLayout.geometry.width;
                            weekLayout.h = weekLayout.geometry.height;
                            weekLayout.x = weekLayout.geometry.x;
                            weekLayout.y = weekLayout.geometry.y;
                        })
                    }
                    if(vm.widget.dateLayout.geometry){
                        vm.widget.dateLayout.width = vm.widget.dateLayout.geometry.width;
                        vm.widget.dateLayout.height = vm.widget.dateLayout.geometry.height;
                    }
                    if(vm.widget.dateLayout.items&&vm.widget.dateLayout.items.item){
                        vm.widget.dateLayout.items = vm.widget.dateLayout.items.item;
                        vm.widget.dateLayout.items.forEach(function (dateLayout) {
                            dateLayout.w = dateLayout.geometry.width;
                            dateLayout.h = dateLayout.geometry.height;
                            dateLayout.x = dateLayout.geometry.x;
                            dateLayout.y = dateLayout.geometry.y;
                        })
                    }
                    if(vm.widget.weekStyles&&vm.widget.weekStyles.item){
                        vm.widget.weekStyles = vm.widget.weekStyles.item;
                    }
                    if(vm.widget.weekStyles){
                        vm.widget.weekStyles.forEach(function (weekStyle) {
                            if(weekStyle.items.item){
                                weekStyle.items = weekStyle.items.item;
                                weekStyle.items.forEach(function (item){
                                    if(item.geometry){
                                        item.w = item.geometry.width;
                                        item.h = item.geometry.height;
                                        item.x = item.geometry.x;
                                        item.y = item.geometry.y;
                                    }
                                })
                            }
                        })
                    }
                    if(vm.widget.dateStyles&&vm.widget.dateStyles.item){
                        vm.widget.dateStyles = vm.widget.dateStyles.item;
                    }
                    if(vm.widget.dateStyles){
                        vm.widget.dateStyles.forEach(function (dateStyle) {
                            if(dateStyle.items.item){
                                dateStyle.items = dateStyle.items.item;
                                dateStyle.items.forEach(function (item,index){
                                    if(item.geometry){
                                        item.w = item.geometry.width;
                                        item.h = item.geometry.height;
                                        item.x = item.geometry.x;
                                        item.y = item.geometry.y;
                                    }
                                })
                            }
                        })
                    }
                }
                if(!vm.widget.ctrl){
                    vm.widget.ctrl={}
                }
            }
            function onCellSizeChange(callback,isNew) {
                if(vm.widget.obj&&vm.widget.obj.heads&&vm.widget.obj.days){
                    var column = vm.widget.obj.heads.length || 7;
                    var row = Math.ceil(vm.widget.obj.days.length/column);
                    var wc = vm.widget.weekConfig;
                    var dc = vm.widget.dateConfig;
                    var headWidth = Number.parseInt(wc.cellWidth || 40) * column + Number.parseInt(wc.margin || 0) * (column-1);
                    var bodyWidth = Number.parseInt(dc.cellWidth || 40) * column + Number.parseInt(dc.margin || 0) * (column-1);

                    var tableHeight = Number(wc.cellHeight ||20) + (Number(dc.cellHeight || 40)) * row + Number(dc.margin || 0) * (row-1) + Number(dc.headSpacing || 0);
                    var width = Math.max(headWidth, bodyWidth);

                    var old = angular.copy(vm.widget);

                    vm.widget.width = width;
                    vm.widget.height = tableHeight;
                    vm.widget.headWidth = headWidth;
                    vm.widget.bodyWidth = bodyWidth;
                    if(isNew && !vm.widget.geometry){
                        vm.widget.ctrl.width = vm.widget.width;
                        vm.widget.ctrl.height = vm.widget.height;
                    }
                    if(vm.update && vm.widget.geometry){
                        vm.widget.geometry.width = vm.widget.width / old.width * vm.widget.geometry.width;
                        vm.widget.geometry.height = vm.widget.height / old.height * vm.widget.geometry.height;
                        vm.update = false;
                    }else{
                        if(callback){callback()}
                    }
                }
            }
            vm.isSize = false;
            function queryMonthdaysDataset(callback) {
                if(vm.widget.ctrl.dateLayout && vm.widget.ctrl.weekLayout){
                    if(vm.widget.obj){
                        vm.isSize = false;
                        onCellSizeChange(function () {
                            if(callback){callback()}
                        })
                        return
                    }
                    vm.isSize = true;
                    vm.widget.obj = null;
                    var req = {
                        year: vm.year || 2023,
                        month: vm.month || 1,
                        startOfWeek: vm.week || 0,
                    };
                    var dateFormats = [];
                    var dateLayout = vm.widget.dateLayout || {};
                    if (dateLayout.items && dateLayout.items.length) {
                        for (var i = 0; i < dateLayout.items.length; i++) {
                            var item = dateLayout.items[i];
                            if (item.type == 'date') {
                                dateFormats.push({type: item.type, format: item.format});
                            }
                        }
                    }
                    var weekFormats = [];
                    var weekLayout = vm.widget.weekLayout || {};
                    if (weekLayout.items && weekLayout.items.length) {
                        for (var i = 0; i < weekLayout.items.length; i++) {
                            var item = weekLayout.items[i];
                            if (item.type == 'week') {
                                weekFormats.push({type: item.type, format: item.format});
                            }
                        }
                    }

                    req.dateFormats = dateFormats;
                    req.weekFormats = weekFormats;
                    CalendarService.queryMonthTable(req, function (res) {
                        var _heads = [];
                        for(var i=0; i<displayType(vm.widget.displayType)/7; i++){
                            res.data.heads.forEach(function (item) {
                                _heads.push(item)
                            })
                        }
                        res.data.heads = _heads;
                        vm.widget.obj = res.data || {};
                        onCellSizeChange(function () {
                            if(callback){callback()}
                        },true)
                    });
                }
            }
            var a = null;
            function reconstructTable() {
                if (!vm.widget.obj) {
                    return;
                }
                if (!vm.widget.obj.heads || !vm.widget.obj.heads.length) {
                    return;
                }
                if (!vm.widget.obj.days || !vm.widget.obj.days.length) {
                    return;
                }
                if (!vm.widget) {
                    return;
                }
                if (!vm.widget.weekConfig || !vm.widget.dateConfig) {
                    return;
                }
                vm.data.clientWidth = vm.widget.ctrl.width;
                vm.data.clientHeight = vm.widget.ctrl.height;
                vm.data.headCellWidth = (vm.widget.weekConfig.cellWidth || 60) * vm.widget.ctrl.width / vm.widget.width;
                vm.data.headCellHeight = (vm.widget.weekConfig.cellHeight || 30) * vm.widget.ctrl.height / vm.widget.height;
                vm.data.headCellMargin = (vm.widget.weekConfig.margin || 0) * vm.widget.ctrl.width / vm.widget.width;
                vm.data.headSpacing = (vm.widget.dateConfig.headSpacing || 0) * vm.widget.ctrl.height / vm.widget.height;
                vm.data.dateCellWidth = (vm.widget.dateConfig.cellWidth || 60) * vm.widget.ctrl.width / vm.widget.width;
                vm.data.dateCellHeight = (vm.widget.dateConfig.cellHeight || 60) * vm.widget.ctrl.height / vm.widget.height;
                vm.data.dateCellMargin = (vm.widget.dateConfig.margin || 0) * vm.widget.ctrl.width / vm.widget.width;
                vm.data.dateCellMarginH = (vm.widget.dateConfig.margin || 0) * vm.widget.ctrl.height / vm.widget.height;
                var column = vm.widget.obj.heads.length || 7;
                vm.data.headWidth = Number(vm.data.headCellWidth) * column + vm.data.headCellMargin * (column-1);
                vm.data.bodyWidth = Number(vm.data.dateCellWidth) * column + vm.data.dateCellMargin * (column-1);

                var dayCount = vm.widget.obj.days.length;
                var cols = displayType(vm.widget.displayType);
                var rows = Math.ceil(dayCount / cols);

                vm.data.cols = cols;
                vm.data.rows = rows;
                vm.data.heads = [];
                vm.data.dates = [];

                var index = 0;
                for (var r = 0; r < rows; r++) {

                    var dateRow = [];
                    for (var c = 0; c < cols; c++) {

                        if (r == 0) {
                            var headObj = vm.widget.obj.heads[c];
                            var headSettings = findHeadSettings(c, headObj);
                            var head = {
                                width: vm.data.headCellWidth,
                                height: vm.data.headCellHeight,
                                margin: vm.data.headCellMargin,
                                obj: headObj,
                                settings: headSettings,
                                layout: vm.widget.ctrl.weekLayout
                            };
                            vm.data.heads.push(head);
                        }

                        // for dates
                        index = r * cols + c;
                        var dateObj = vm.widget.obj.days[index];
                        if(dateObj){
                            var dateSettings = findDateSettings(c, dateObj);
                            var date = {
                                width: vm.data.dateCellWidth,
                                height: vm.data.dateCellHeight,
                                margin: vm.data.dateCellMargin,
                                obj: dateObj,
                                settings: dateSettings,
                                layout: vm.widget.ctrl.dateLayout
                            };
                            dateRow.push(date);
                        }else{
                            var date = {
                                width: vm.data.dateCellWidth,
                                height: vm.data.dateCellHeight,
                                margin: vm.data.dateCellMargin,
                                obj: null,
                                settings: null,
                                layout: vm.widget.ctrl.dateLayout
                            };
                            dateRow.push(date);
                        }
                    }
                    vm.data.dates.push(dateRow);
                }

                vm.data.bodyHeight = vm.data.dateCellHeight * rows + vm.data.dateCellMarginH * (rows-1);
                vm.data.clientHeight = vm.data.bodyHeight + vm.data.headCellHeight + vm.data.headSpacing;
                if(vm.thumbnail){
                    vm.data.styles = {
                        width: vm.data.clientWidth + 'px',
                        height: vm.data.clientHeight + 'px',
                        'transform' : 'scale('+(vm.scale || 1) + ')',
                        'transform-origin' : '0px 0px',
                    };
                }else{
                    vm.data.styles = {
                        width: vm.data.clientWidth + 'px',
                        height: vm.data.clientHeight + 'px',
                        zoom:vm.scale || 1,
                    };
                }
                if (vm.widget.backgroundColor) {
                    vm.data.styles['background-color'] = vm.widget.backgroundColor;
                }
                vm.loading = true;
                if (a !== null) {
                    clearTimeout(a)
                }
                a = setTimeout(function () {
                    vm.loading = false;
                    $scope.$apply();
                },500)
            }

            function findHeadSettings (indexOfWeek, headObj) {
                var styleUuid;

                if (vm.widget && vm.widget.weekConfig) {
                    var w = indexOfWeek;
                    if (vm.widget.startOfWeek == 1) {
                        // 每周从周一开始
                        w += 1;
                    }
                    else if (indexOfWeek == 0) {
                        // 周日
                        w = 7;
                    }
                    w = w - Math.trunc((w-1)/7)*7;
                    styleUuid = vm.widget.weekConfig["w" + w];
                }
                if (!styleUuid) {
                    styleUuid = "default";
                }
                var defaultStyle;
                if (vm.widget.weekStyles && vm.widget.weekStyles.length) {
                    for (var i = 0; i < vm.widget.weekStyles.length; i++) {
                        var style = vm.widget.weekStyles[i];
                        if (style.defaultStyle) {
                            defaultStyle = style;
                            if ("default" == styleUuid) {
                                return style;
                            }
                        }
                        if (style.uuid == styleUuid) {
                            return style;
                        }
                    }
                    if (!defaultStyle) {
                        defaultStyle = vm.widget.weekStyles[0];
                    }
                }
                if (defaultStyle) {
                    return defaultStyle;
                }
                return { debug: true};
            }

            function findDateSettings (indexOfWeek, dateObj) {
                var others = false;
                if (dateObj.year != vm.widget.obj.year || dateObj.month != vm.widget.obj.month) {
                    others = true;
                }
                var styleUuid;
                if (vm.widget && vm.widget.dateConfig) {
                    if (others) {
                        styleUuid = vm.widget.dateConfig.others;
                    } else {
                        var w = indexOfWeek;
                        if (vm.widget.startOfWeek == 1) {
                            // 每周从周一开始
                            w += 1;
                        }
                        else if (indexOfWeek == 0) {
                            // 周日
                            w = 7;
                        }
                        w = w - Math.trunc((w-1)/7)*7;
                        styleUuid = vm.widget.dateConfig["w" + w];
                    }
                }
                if (!styleUuid) {
                    styleUuid = "default";
                }
                if(dateObj.holiday && !others && dateObj.dayOfWeek!=7 && dateObj.dayOfWeek!=6){
                    styleUuid = vm.widget.dateConfig.holiday;
                }
                var defaultStyle;
                if (vm.widget.dateStyles && vm.widget.dateStyles.length) {
                    for (var i = 0; i < vm.widget.dateStyles.length; i++) {
                        var style = vm.widget.dateStyles[i];
                        if (style.defaultStyle) {
                            if ("default" == styleUuid) {
                                defaultStyle = angular.copy(style);
                                break
                            }
                        }
                        if (style.uuid == styleUuid) {
                            defaultStyle = angular.copy(style);
                            break
                        }
                    }
                    if (!defaultStyle) {
                        defaultStyle = angular.copy(vm.widget.dateStyles[0]);
                    }
                }
                if (defaultStyle) {
                    defaultStyle.showOthers = true;
                    if(others){
                        defaultStyle.showOthers = vm.widget.showOthers;
                    }
                    if(!others && (dateObj.dayOfWeek==7 || dateObj.dayOfWeek==6) && dateObj.holiday){
                        var lunarStyle=null;
                        for (var i = 0; i < vm.widget.dateStyles.length; i++) {
                            var style = vm.widget.dateStyles[i];
                            if (style.uuid == vm.widget.dateConfig.holiday) {
                                style.items.forEach(function(item){
                                    if(item.name == '农历日'){
                                        lunarStyle = angular.copy(item);
                                    }
                                })
                                break
                            }
                        }
                        if(lunarStyle){
                            defaultStyle.items.forEach(function(item,index){
                                if(item.name == '农历日'){
                                    defaultStyle.items[index] = angular.copy(lunarStyle);
                                }
                            })
                        }
                    }
                    return angular.copy(defaultStyle);
                }
                return { debug: true};
            }

            function orMockWeekLayout(layout) {
                if (layout) {
                    return layout;
                }
                else {
                    var l = {
                        type: "WEEK",
                        width: 100,
                        height: 50,
                        items: [{
                            type: "week",
                            format: "$cnWeek",
                            value: "星期日",
                            textSize: 18,
                            textFont: '',
                            textColor: '#000000',
                            textWeight: 500,
                            x: 5,
                            y: 10,
                            w: 90,
                            h: 30
                        }]
                    };
                    return l;
                }
            }
            function orMockDateLayout(layout) {
                if (layout) {
                    return layout;
                }
                else {
                    var l = {
                        type: "DATE",
                        width: 100,
                        height: 100,
                        items: [{
                            type: "date",
                            format: "$dd",
                            value: "01",
                            textSize: 18,
                            textFont: '',
                            textColor: '#ff0000',
                            textWeight: 500,
                            x: 5,
                            y: 15,
                            w: 90,
                            h: 40
                        }]
                    };
                    return l;
                }
            }

        }]
    };
    angular.module('platformApp')
        .component('monthTable', monthTableControl);

})();
