var menuImageView = '?imageMogr2/thumbnail/200x200>'; //左侧菜单栏图片大小
var pageImageView = '?imageMogr2/thumbnail/800x800>'; //画布图片大小
var pageImageViewOrient = '?imageMogr2/auto-orient/thumbnail/800x800>'//画布图片加自动校正

//移动画布
var isMoveCanvas = false;
function moveCanvas($event, callback) {
    var x = $event.clientX;
    var y = $event.clientY;
    isMoveCanvas = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (!isMoveCanvas) {
            return;
        }
        // console.log('canvas moving');
        callback(e.clientX - x, e.clientY - y)
    };
    //鼠标抬起事件
    window.onmouseup = function ($event) {
        isMoveCanvas = false;
    }
}
//page排序
function pageSeq(page, UidService, callback) {
    page.forEach(function (item, index) {
        item.seq = index + 1;
        // item.uuid = UidService.get();
    })
    callback(page)
}

function getDPI() {
    var arrDPI = new Array();
    if (window.screen.deviceXDPI != undefined) {
        arrDPI[0] = window.screen.deviceXDPI;
        arrDPI[1] = window.screen.deviceYDPI;
    } else {
        var tmpNode = document.createElement("DIV");
        tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
        document.body.appendChild(tmpNode);
        arrDPI[0] = parseInt(tmpNode.offsetWidth);
        arrDPI[1] = parseInt(tmpNode.offsetHeight);
        tmpNode.parentNode.removeChild(tmpNode);
    }
    return arrDPI[0];
}
// mm换算成px
function MmTurnPx(mm) {
    return (mm / 25.4 * this.getDPI()).toFixed(2) - 0;
};
// px换算成mm
function PxTurnMm(px) {
    return (px / this.getDPI() * 25.4).toFixed(2) - 0;
};
//px换算成英寸
function PxTurnIn(item) {
    return (item / this.getDPI()).toFixed(2) - 0;
};
//英寸换算成px
function InTurnPx(item) {
    return (item * this.getDPI()).toFixed(2) - 0;
};
//英寸换算成mm
function InTurnMm(item) {
    return PxTurnMm(InTurnPx(item));
};
//mm换算成英寸
function MmTurnIn(item) {
    return PxTurnIn(MmTurnPx(item));
};
//pt换算成px
function ptToPx(pt) {
    var px = pt * this.getDPI() / 72;
    return px;
}

/*******************************************素材处理*************************************************************************/
//添加素材
function imageartAdd($http, imageart,mediabox, callback) {
    $http({url: imageart.resource.identifier + '?imageInfo'}).success(function (data) {
        var _imageart = {
            onlyshow: false,
            movable: true,
            editable: true,
            lock: false,
            offsetx: '0',
            offsety: '0',
            transparency: '1',
            geometry: {
                x: '0',
                y: '0',
                width: data.width,
                height: data.height
            },
            rotation: {
                angle: '0'
            },
            resource: {
                provider: '',
                identifier: imageart.identifier,
                width: '',
                height: ''
            },
            scale: 1,
        }

        var bgImgW = data.width;
        var bgImgH = data.height;
        var bgImgR = bgImgW / bgImgH;
        if (bgImgR >= 1) {
            if (bgImgW > mediabox.width) {
                _imageart.geometry.width = mediabox.width * 0.5;
                _imageart.geometry.height = _imageart.geometry.width * bgImgH / bgImgW;
            } else {
                _imageart.geometry.width = bgImgW;
                _imageart.geometry.height = bgImgH;
            }
        } else {
            if (bgImgH > mediabox.height) {
                _imageart.geometry.height = mediabox.height * 0.5;
                _imageart.geometry.width = _imageart.geometry.height * bgImgW / bgImgH;
            } else {
                _imageart.geometry.width = bgImgW;
                _imageart.geometry.height = bgImgH;
            }
        }
        _imageart.resource.width = _imageart.geometry.width;
        _imageart.resource.height = _imageart.geometry.height;
        _imageart.geometry.x = (mediabox.width - _imageart.geometry.width) / 2 + mediabox.x;
        _imageart.geometry.y = (mediabox.height - _imageart.geometry.height) / 2 + mediabox.y;
        callback(_imageart)
    }).error(function (error) {
    });
}
//像素不足
function pxInsufficient(imageW, imageH, imageScale, geometryW, geometryH) {
    var scale = imageScale || 1;
    var dpi = Math.ceil(imageW*25.4/geometryW / scale);
    return dpi;
}

function imageInfo($http, res, callback){
    $http({url: res.identifier + '?imageInfo'}).success(function (data) {
        res.width = data.width;
        res.height = data.height;
        res.size = data.size;
        callback()
    }).error(function (error) {
        console.error("获取图片信息失败");
        // MessageService.error("获取图片信息失败");
    });
}
function getUuid(len, radix) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    var uuid = [],
        i;
    radix = radix || chars.length;

    if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
        // rfc4122, version 4 form
        var r;

        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';

        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random() * 16;
                uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }

    return uuid.join('');
}
/*******************************************元素缩放处理*************************************************************************/
// 通用的缩放算法
// $event: 鼠标事件
// item: 用户数据，item.geometry可用
// stick :  left - 左
//          leftTop - 左上
//          top - 上
//          rightTop - 右上
//          right - 右
//          rightBottom - 右下
//          bottom - 下
//          leftBottom - 左下
//
//      leftTop ---------- top ----------- rightTop
//          |                                   |
//          |                                   |
//         left                               right
//          |                                   |
//          |                                   |
//       leftBottom ----- bottom ---------- rightBottom
//
//  boxElement: box框元素，可以用来计算box的位置和尺寸
var boxZoomStickDown = false;
function editorBoxZoom($event, item, stick, scale, boxElement, moveCallback, upCallback) {
    fn($event)
    if (!item || !stick || !boxElement) {
        return;
    }
    var element;
    if (boxElement.offset) {
        // 元素组
        element = boxElement[0];
    } else if (boxElement.offsetLeft) {
        element = boxElement;
    }
    boxZoomStickDown = true;
    var l0 = element.offsetLeft;
    var t0 = element.offsetTop;
    var w0 = element.offsetWidth;
    var h0 = element.offsetHeight;
    var cx = l0 + w0 / 2;   // 原中心点x
    var cy = t0 + h0 / 2;   // 原中心点y
    var angle = Number(item.rotation.angle);
    if(item.current == 'imagebox'){
        angle = 360-Number(item.rotation.angle);
    }
    var pt0 = rotatePoint({x: $event.clientX, y: $event.clientY}, {x: cx, y: cy}, -angle);
    var dx = 0;     // 框left变化
    var dy = 0;     // 框top变化
    var dw = 0;     // 框长变化
    var dh = 0;     // 框高变化
    var dcx = 0;    // 中心点x向偏移
    var dcy = 0;    // 中心点y向偏移

    var gx = item.geometry.x;
    var gy = item.geometry.y;
    var gw = item.geometry.width;
    var gh = item.geometry.height;
    if(item.offsetx || item.offsety){
        var nw = angular.copy(item.geometry.width);
        var nh = angular.copy(item.geometry.height);
        var nw1 = angular.copy(item.resource.width);
        var nh1 = angular.copy(item.resource.height);
        var x1 = angular.copy(item.offsetx);
        var y1 = angular.copy(item.offsety);
    }
    if(item.style){
        var nw = angular.copy(item.geometry.width);
        var nh = angular.copy(item.geometry.height);
        var _size = angular.copy(parseInt(item.style.size));
        var _lineheight = angular.copy(parseInt(item.style.lineheight));
    }


    function px2mm(pixels) {
        return (pixels || 0) * 25.4 / 96;
    }

    window.onmousemove = function (e) {
        // fn(e)
        if (!boxZoomStickDown) {
            // e.stopPropagation();
            return;
        }
        var pt1 = rotatePoint({x: e.clientX, y: e.clientY}, {x: cx, y: cy}, -angle);
        if (stick == 'left') {
            dx = pt1.x - pt0.x;
            dw = -dx;
            dcx = dx/2;
        }
        else if (stick == 'top') {
            dy = pt1.y - pt0.y;
            dh = -dy;
            dcy = dy/2;
        }
        else if (stick == 'right') {
            dw = pt1.x - pt0.x;
            dcx = dw / 2;
        }
        else if (stick == 'bottom') {
            dh = pt1.y - pt0.y;
            dcy = dh / 2;
        }
        else if (stick == 'leftTop') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw - offsetX / scale) * gh  > (gh - offsetY / scale) * gw ) {
                offsetX = offsetY * gw / gh;
            } else {
                offsetY = offsetX * gh / gw;
            }

            dx = offsetX;
            dy = offsetY;

            dw = -dx;
            dh = -dy;
            dcx = dx / 2;
            dcy = dy / 2;
        }
        else if (stick == 'leftBottom') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw - offsetX / scale) * gh  > (gh + offsetY / scale) * gw ) {
                offsetX = -1 * offsetY * gw / gh;
            } else {
                offsetY = -1 * offsetX * gh / gw;
            }

            dx = offsetX;
            dw = -dx;
            dh = offsetY;
            dcx = dx / 2;
            dcy = dh / 2;
        }
        else if (stick == 'rightTop') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw + offsetX / scale) * gh  > (gh - offsetY / scale) * gw ) {
                offsetX = -1 * offsetY * gw / gh;
            } else {
                offsetY = -1 * offsetX * gh / gw;
            }

            dy = offsetY;
            dw = offsetX;
            dh = -dy;
            dcx = dw / 2;
            dcy = dy / 2;
        }
        else if (stick == 'rightBottom') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw + offsetX / scale) * gh  > (gh + offsetY / scale) * gw ) {
                offsetX = offsetY * gw / gh;
            } else {
                offsetY = offsetX * gh / gw;
            }

            dw = offsetX;
            dh = offsetY;
            dcx = dw / 2;
            dcy = dh / 2;
        }
        else {
            console.error('zoom-dragging - invalid stick: ' + stick);
            e.stopPropagation();
            return;
        }
        // 如果框尺寸小于5mm, 不处理
        if (gw + (dw / scale) < 5 || gh + (dh / scale) < 5) {
            e.stopPropagation();
            return;
        }
        // 中心点偏移dc1
        var dc1 = rotatePoint({x: dcx, y: dcy}, {x: 0, y: 0}, angle);
        // 偏移补偿
        var cx_fix =  dc1.x - dcx;
        var cy_fix = dc1.y - dcy;

        item.geometry.width = gw + (dw / scale);
        item.geometry.height = gh + (dh / scale);
        if(item.style){
            if(item.geometry.width > ptToPx(item.style.size)){
                item.geometry.x = gx + ((dx + cx_fix) / scale);
                item.geometry.y = gy + ((dy + cy_fix) / scale);
            }
        }else{
            item.geometry.x = gx + ((dx + cx_fix) / scale);
            item.geometry.y = gy + ((dy + cy_fix) / scale);
        }
        if(item.offsetx || item.offsety){
            item.offsetx = ((gw + dw / scale) / (nw / nw1)) / (nw1 / x1);
            item.offsety = ((gh + dh / scale) / (nh / nh1)) / (nh1 / y1);
        }

        if(item.style){
            if((stick != 'left' && stick != 'top' && stick != 'right' && stick != 'bottom')){
                var size1 = Math.ceil((gw + (dw / scale)) / nw * _size);
                if(size1<6){
                    size1 = 6;
                    item.style.size = size1;
                    item.style.lineheight = Math.floor((size1 / _size * _lineheight));
                    boxZoomStickDown = false;
                    if (upCallback) {
                        upCallback(item);
                    }
                    return false
                }
                item.style.size = size1;
                item.style.lineheight = Math.floor((size1 / _size * _lineheight));
            }
            if(stick == 'left'|| stick == 'right'){
                if(item.geometry.width < ptToPx(item.style.size)){
                    item.geometry.width = ptToPx(item.style.size)+10;
                    boxZoomStickDown = false;
                    if (upCallback) {
                        upCallback(item);
                    }
                }
            }
            if(stick == 'top' || stick == 'bottom'){
                if(item.geometry.height < ptToPx(item.style.size)){
                    item.geometry.height = ptToPx(item.style.size)+10;
                    boxZoomStickDown = false;
                    if (upCallback) {
                        upCallback(item);
                    }
                }
            }
        }


        // console.log('dx=' + dx + ", dy=" + dy + ', dw=' + dw + ', dh=' + dh);

        if (moveCallback) {
            moveCallback(item);
        }
        // e.stopPropagation();
    }

    window.onmouseup = function (e) {
        // fn(e)
        if (!boxZoomStickDown) {
            // e.stopPropagation();
            return;
        }
        var pt1 = rotatePoint({x: e.clientX, y: e.clientY}, {x: cx, y: cy}, -angle);
        if (stick == 'left') {
            dx = pt1.x - pt0.x;
            dw = -dx;
            dcx = dx/2;
        }
        else if (stick == 'top') {
            dy = pt1.y - pt0.y;
            dh = -dy;
            dcy = dy/2;
        }
        else if (stick == 'right') {
            dw = pt1.x - pt0.x;
            dcx = dw / 2;
        }
        else if (stick == 'bottom') {
            dh = pt1.y - pt0.y;
            dcy = dh / 2;
        }
        else if (stick == 'leftTop') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw - offsetX / scale) * gh  > (gh - offsetY / scale) * gw ) {
                offsetX = offsetY * gw / gh;
            } else {
                offsetY = offsetX * gh / gw;
            }

            dx = offsetX;
            dy = offsetY;

            dw = -dx;
            dh = -dy;
            dcx = dx / 2;
            dcy = dy / 2;
        }
        else if (stick == 'leftBottom') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw - offsetX / scale) * gh  > (gh + offsetY / scale) * gw ) {
                offsetX = -1 * offsetY * gw / gh;
            } else {
                offsetY = -1 * offsetX * gh / gw;
            }

            dx = offsetX;
            dw = -dx;
            dh = offsetY;
            dcx = dx / 2;
            dcy = dh / 2;
        }
        else if (stick == 'rightTop') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw + offsetX / scale) * gh  > (gh - offsetY / scale) * gw ) {
                offsetX = -1 * offsetY * gw / gh;
            } else {
                offsetY = -1 * offsetX * gh / gw;
            }

            dy = offsetY;
            dw = offsetX;
            dh = -dy;
            dcx = dw / 2;
            dcy = dy / 2;
        }
        else if (stick == 'rightBottom') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw + offsetX / scale) * gh  > (gh + offsetY / scale) * gw ) {
                offsetX = offsetY * gw / gh;
            } else {
                offsetY = offsetX * gh / gw;
            }

            dw = offsetX;
            dh = offsetY;
            dcx = dw / 2;
            dcy = dh / 2;
        }
        else {
            console.error('zoom-dragging - invalid stick: ' + stick);
            // e.stopPropagation();
            return;
        }

        // 如果框尺寸小于5mm, 不处理
        if (gw + (dw / scale) < 5 || gh + (dh / scale) < 5) {
            // e.stopPropagation();
            if (upCallback) {
                upCallback(item);
            }
            boxZoomStickDown = false;
            return;
        }

        // 中心点偏移dc1
        var dc1 = rotatePoint({x: dcx, y: dcy}, {x: 0, y: 0}, angle);
        // 偏移补偿
        var cx_fix =  dc1.x - dcx;
        var cy_fix = dc1.y - dcy;

        if(!item.style){
            item.geometry.x = gx + ((dx + cx_fix) / scale);
            item.geometry.y = gy + ((dy + cy_fix) / scale);
            item.geometry.width = gw + (dw / scale);
            item.geometry.height = gh + (dh / scale);
        }

        if (upCallback) {
            upCallback(item);
        }
        boxZoomStickDown = false;
        // e.stopPropagation();
    }
}

function rotatePoint(ptSrc, ptRotationCenter, angle){
    var a = ptRotationCenter.x;
    var b = ptRotationCenter.y;
    var x0 = ptSrc.x;
    var y0 = ptSrc.y;
    var rx = a + (x0-a) * Math.cos(angle * Math.PI / 180) - (y0-b) * Math.sin(angle * Math.PI / 180);
    var ry = b + (x0-a) * Math.sin(angle * Math.PI / 180) + (y0-b) * Math.cos(angle * Math.PI / 180);
    var json = {x:rx,y:ry}
    return json;
}

function imageboxImg(imagebox, callback) {
    var newImg = new Image();
    newImg.crossOrigin = "Anonymous";
    newImg.src = imagebox.image.resource.identifier + '?imageMogr2/strip/rotate/' + (imagebox.image.angle || 0) + '/thumbnail/!40p';
    newImg.onload = function () {
        var imgR = imagebox.geometry.width / imagebox.geometry.height;
        var imgR1 = newImg.width / newImg.height;
        if (imgR >= imgR1) {
            imagebox.image.resource.adaptation = 'Height';
            imagebox.image.width = imagebox.geometry.width;
            imagebox.image.height = imagebox.geometry.width / newImg.width * newImg.height;
            imagebox.image.offsetx = 0;
            imagebox.image.offsety = -(imagebox.image.height - imagebox.geometry.height) / 2;
        } else {
            imagebox.image.resource.adaptation = 'Width';
            imagebox.image.width = imagebox.geometry.height / newImg.height * newImg.width;
            imagebox.image.height = imagebox.geometry.height;
            imagebox.image.offsetx = -(imagebox.image.width - imagebox.geometry.width) / 2;
            imagebox.image.offsety = 0;
        }
        if(callback){
            callback()
        }
    };
}

function fontuuid2family(fontuuid) {
    if (fontuuid && !fontuuid.startsWith("font_")) {
        return "font_" + fontuuid.replaceAll("-", "_");
    }
    return fontuuid;
}
//文字校验
function fontSupport(FontManagement, font, callback) {
    FontManagement.fontSupport({
        uuid: font.fontuuid,
        bold: font.style.bold,
        italic: font.style.italic,
        content: font.text
    }, function (res) {
        if(res.status == 200){
            font.success = res.message.success;
        }
        if(callback)(callback())
    }, function (error) {
        if(callback){callback()}
    })
}
function dropShadow(imagebox,width,height){
    if(imagebox.shadow.x == 0 && imagebox.shadow.y == 0 && imagebox.shadow.blur == 0){
        return ''
    }
    var blur = 0;
    var x = 0;
    var y = 0;
    if (width>=height) {
        blur = width * imagebox.shadow.blur * 0.005
    }else{
        blur = height * imagebox.shadow.blur * 0.005
    }
    x = width * imagebox.shadow.x * 0.01;
    y = height * imagebox.shadow.y * 0.01;
    return 'drop-shadow('+x+'px '+y+'px '+blur+'px '+imagebox.shadow.color+')'
}
//生成缩略图
function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
}

/*
    x0,y0,左上角坐标
    x1,y1,右下角坐标
    angle,旋转角度
    return 旋转后大盒子宽高，坐标
*/
function outerRectangle(x0, y0, x1, y1, angle) {
    var angle = angle || 0;
    var cx = (x0 + x1) / 2;
    var cy = (y0 + y1) / 2;
    var pt0 = rotatePoint({x: x0, y: y0}, {x: cx, y: cy}, angle);
    var pt1 = rotatePoint({x: x1, y: y0}, {x: cx, y: cy}, angle);
    var pt2 = rotatePoint({x: x1, y: y1}, {x: cx, y: cy}, angle);
    var pt3 = rotatePoint({x: x0, y: y1}, {x: cx, y: cy}, angle);

    var minX = Math.min(pt0.x, pt1.x, pt2.x, pt3.x);
    var minY = Math.min(pt0.y, pt1.y, pt2.y, pt3.y);
    var maxX = Math.max(pt0.x, pt1.x, pt2.x, pt3.x);
    var maxY = Math.max(pt0.y, pt1.y, pt2.y, pt3.y);

    return {
        x: minX,
        y: minY,
        x1:maxX,
        y1:maxY,
        width: maxX - minX,
        height: maxY - minY,
    };
}


//画布上下左右对齐，贴边
function elementAlign(item, angle, direction, pageWidth, pageHeight) {
    var x0 = item.x;
    var y0 = item.y;
    var x1 = item.x + item.width;
    var y1 = item.y + item.height;
    var outer = outerRectangle(x0, y0, x1, y1, angle);
    if(direction == 'left') {
        return (outer.width-item.width)/2
    }
    if(direction == 'right') {
        return pageWidth - item.width - (outer.width - item.width)/2
    }
    if(direction == 'top') {
        return (outer.height-item.height)/2
    }
    if(direction == 'bottom') {
        return pageHeight - item.height - (outer.height - item.height)/2
    }
}
function coordinates(level,levelI) {
    var _data={
        x:[],
        y:[],
    }
    if(level){
        level.forEach(function (level,index) {
            if(levelI != index){
                var _coordinate = null;
                if(level.imagebox&&level.imagebox.geometry){
                    _coordinate = coordinatesItem(level.imagebox.geometry,level.imagebox.rotation.angle);
                }
                if(level.imageart&&level.imageart.geometry){
                    _coordinate = coordinatesItem(level.imageart.geometry,level.imageart.rotation.angle);
                }
                if(level.textbox&&level.textbox.geometry){
                    _coordinate = coordinatesItem(level.textbox.geometry,level.textbox.rotation.angle);
                }
                if(level.textdocument&&level.textdocument.geometry){
                    _coordinate = coordinatesItem(level.textdocument.geometry,level.textdocument.rotation.angle);
                }
                if(level.calTitleBox&&level.calTitleBox.geometry){
                    _coordinate = coordinatesItem(level.calTitleBox.geometry,level.calTitleBox.rotation.angle);
                }
                if(level.calMonthBox&&level.calMonthBox.geometry){
                    _coordinate = coordinatesItem(level.calMonthBox.geometry,level.calMonthBox.rotation.angle);
                }
                if(_coordinate){
                    _data.x = _data.x.concat(_coordinate.x);
                    _data.y = _data.y.concat(_coordinate.y);
                }
            }
        })
    }
    return _data
}
function coordinatesItem(geometry,angle) {
    var x0 = geometry.x;
    var y0 = geometry.y;
    var x1 = geometry.x + geometry.width;
    var y1 = geometry.y + geometry.height;
    var outer = outerRectangle(x0, y0, x1, y1, angle);
    return {
        x:[outer.x, outer.x1],
        y:[outer.y, outer.y1],
    }
}
function getItemCoordinates(box,coordinates,callback) {
    var _thisItem = coordinatesItem(box.geometry,box.rotation.angle);
    var _x = approach(_thisItem.x, coordinates.x);
    var _y = approach(_thisItem.y, coordinates.y);
    var _z = {};
    if(_x.minDiff < 10){
        _z.x=_x;
    }
    if(_y.minDiff < 10){
        _z.y=_y;
    }
    callback(_z)
}

function approach(a,b) {
    var _a = a[0];
    var closestNum = b[0]; // 初始化最接近的数字为b数组的第一个元素
    var minDiff = Math.abs(a[0] - closestNum); // 初始化差值绝对值为a数组第一个元素与最接近数字的差值

    for (var i = 0; i < a.length; i++) {
        for (var j = 0; j < b.length; j++) {
            var diff = Math.abs(a[i] - b[j]); // 计算差值绝对值
            if (diff < minDiff) { // 如果差值绝对值更小，更新最接近数字和最小差值绝对值
                minDiff = diff;
                closestNum = b[j];
                _a = a[i];
            }
        }
    }
    return {
        a:_a,
        closestNum:closestNum,
        minDiff:minDiff,
        diff:_a > closestNum ? -minDiff : minDiff,
    }
}
function documentTextDetection(document) {
    var isPass = {
        pass: true,
        pages:[],
    };
    if(document.backupPages&&document.backupPages.list){
        var page = document.backupPages.list;
        for(var i=0; i<page.length; i++){
            if (!page[i] || !page[i].levels || !page[i].levels.level) {
                continue;
            }
            if (!page[i].levels.level.length) {
                continue;
            }
            var _page={
                valid: false,
                pageIndex: i + 1,
                texts:[],
                repetitions:[],
            }
            for(var j=0; j<page[i].levels.level.length; j++){
                var _level = page[i].levels.level[j];
                if(_level.textbox && _level.textbox.geometry && _level.textbox.success === false){
                    _page.valid = true;
                    isPass.isText = true;
                    _page.texts.push(_level.textbox)
                }
                var _repetition = [];
                for (var key in _level){
                    if(_level[key] && (key=='imagebox'||key=='code'||key=='imageart'||key=='textbox'||key=='textdocument')){
                        _level[key].levelType = key;
                        _repetition.push(_level[key])
                    }
                }
                if(_repetition.length>1){
                    _page.valid = true;
                    isPass.isRepetition = true;
                    _page.repetitions.push(_level);
                }
            }
            if(_page.valid){
                isPass.pass = false;
                isPass.pages.push(_page)
            }
        }
    }
    if(document.pages&&document.pages.page){
        var page = document.pages.page;
        for(var i=0; i<page.length; i++){
            if (!page[i] || !page[i].levels || !page[i].levels.level) {
                continue;
            }
            if (!page[i].levels.level.length) {
                continue;
            }
            var _page={
                valid: false,
                pageIndex: i + 1,
                texts:[],
                repetitions:[],
            }
            for(var j=0; j<page[i].levels.level.length; j++){
                var _level = page[i].levels.level[j];
                if(_level.textbox && _level.textbox.geometry && _level.textbox.success === false){
                    _page.valid = true;
                    isPass.isText = true;
                    _page.texts.push(_level.textbox)
                }
                var _repetition = [];
                for (var key in _level){
                    if(_level[key] && (key=='imagebox'||key=='code'||key=='imageart'||key=='textbox'||key=='textdocument')){
                        _level[key].levelType = key;
                        _repetition.push(_level[key])
                    }
                }
                if(_repetition.length>1){
                    _page.valid = true;
                    isPass.isRepetition = true;
                    _page.repetitions.push(_level);
                }
            }
            if(_page.valid){
                isPass.pass = false;
                isPass.pages.push(_page)
            }
        }
    }
    return isPass
}
function fn(e) {
    if (e && e.stopPropagation) {
        e.stopPropagation();
    } else {
        window.event.cancelBubble = true;
    }
    if (e && e.preventDefault) {
        e.preventDefault();
    } else {
        window.event.returnValue = false;
    }
}


/*
    计算旋转后的元素外框四点坐标
    x0,y0,左上角坐标
    x1,y1,右下角坐标
    angle,旋转角度
    return 旋转后大盒子宽高，坐标
*/
function outerRectangle2(x0, y0, x1, y1, angle) {
    var angle = angle || 0;
    var cx = (x0 + x1) / 2;
    var cy = (y0 + y1) / 2;
    var pt0 = rotatePoint({x: x0, y: y0}, {x: cx, y: cy}, angle);
    var pt1 = rotatePoint({x: x1, y: y0}, {x: cx, y: cy}, angle);
    var pt2 = rotatePoint({x: x1, y: y1}, {x: cx, y: cy}, angle);
    var pt3 = rotatePoint({x: x0, y: y1}, {x: cx, y: cy}, angle);

    var minX = Math.min(pt0.x, pt1.x, pt2.x, pt3.x);
    var minY = Math.min(pt0.y, pt1.y, pt2.y, pt3.y);
    var maxX = Math.max(pt0.x, pt1.x, pt2.x, pt3.x);
    var maxY = Math.max(pt0.y, pt1.y, pt2.y, pt3.y);

    return {
        minX: minX,
        minY: minY,
        maxX: maxX,
        maxY: maxY
    };
}



/**
 * 获取多个元素画框后的最大最小坐标
 * @param {*} list
 * @returns
 */
function getAllElemCoord(list){
    var allX = [];
    var allY = [];
    list.forEach(function(box){
        var outer = outerRectangle2(box.geometry.x, box.geometry.y, box.geometry.x + box.geometry.width, box.geometry.y + box.geometry.height, box.rotation.angle);
        allX.push(outer.minX);
        allY.push(outer.minY);
        allX.push(outer.maxX);
        allY.push(outer.maxY);
    })
    var minX = Math.min.apply(null, allX);
    var minY = Math.min.apply(null, allY);
    var maxX = Math.max.apply(null, allX);
    var maxY = Math.max.apply(null, allY);

    return {
        minX: minX,
        minY: minY,
        maxX: maxX,
        maxY: maxY
    };
}

/**
 * 获取单个元素对齐移动后的坐标（多元素对齐）
 * @param {*} place 对齐方向 top left right bottom
 * @param {*} coord 大框的坐标
 * @param {*} el    元素信息
 * @returns 移动后的x/y轴坐标
 */
function getElemMoveCoord(place,coord,el){
    var geometry = el.geometry;
    var outer = outerRectangle2(geometry.x, geometry.y, geometry.x + geometry.width, geometry.y + geometry.height, el.rotation.angle);
    if(place === "left"){
        if(outer.minX === coord.minX) return geometry.x;
        return geometry.x - (outer.minX - coord.minX);
    }
    if(place === "right"){
        if(outer.maxX === coord.maxX) return geometry.x;
        return geometry.x + (coord.maxX - outer.maxX);
    }
    if(place === "top"){
        if(outer.minY === coord.minY) return geometry.y;
        return geometry.y - (outer.minY - coord.minY);
    }
    if(place === "bottom"){
        if(outer.maxY === coord.maxY) return geometry.y;
        return geometry.y + (coord.maxY - outer.maxY);
    }


}

// 多元素水平垂直居中对齐
function horizontalAndVerticalCenter(attr, all){
    var allX = [];
    var allY = [];
    all.forEach(function(box){
        var outer = outerRectangle2(box.geometry.x, box.geometry.y, box.geometry.x + box.geometry.width, box.geometry.y + box.geometry.height, box.rotation.angle);
        allX.push(outer.minX);
        allY.push(outer.minY);
        allX.push(outer.maxX);
        allY.push(outer.maxY);
    })
    var minX = Math.min.apply(null, allX);
    var minY = Math.min.apply(null, allY);
    var maxX = Math.max.apply(null, allX);
    var maxY = Math.max.apply(null, allY);
    if (attr == 'vertical') {
        return maxY - (maxY - minY)/2;
    }
    if (attr == "standard") {
        return maxX - (maxX - minX)/2;
    }
}

//解析document
function documentAnalysis($scope,$http, document,FontManagement, callbackRatio, callback) {
    angular.forEach(document.pages.page, function (page, index) {
        (function (page, index) {
            page.seq = index;
            page.mediabox = {
                width: MmTurnPx(page.mediabox.width),
                height: MmTurnPx(page.mediabox.height),
                x: MmTurnPx(page.mediabox.x),
                y: MmTurnPx(page.mediabox.y)
            };
            page.trimbox = {
                width: MmTurnPx(page.trimbox.width),
                height: MmTurnPx(page.trimbox.height),
                x: MmTurnPx(page.trimbox.x),
                y: MmTurnPx(page.trimbox.y)
            };
            // 兼容旧模板 可编辑区域
            if (!page.editbox) {
                page.editbox = angular.copy(page.trimbox);
            }else{
                page.editbox = {
                    width: MmTurnPx(page.editbox.width),
                    height: MmTurnPx(page.editbox.height),
                    x: MmTurnPx(page.editbox.x),
                    y: MmTurnPx(page.editbox.y)
                };
            }
            if(!page.editboxes || !page.editboxes.editbox){
                page.editboxes = {
                    editbox: [page.editbox]
                }
            }else{
                page.editboxes.editbox.forEach(function (editbox, editboxIndex) {
                    page.editboxes.editbox[editboxIndex] = {
                        width: MmTurnPx(editbox.width),
                        height: MmTurnPx(editbox.height),
                        x: MmTurnPx(editbox.x),
                        y: MmTurnPx(editbox.y)
                    }
                })
            }
            page.custom = {};
            if (page.scene) {
                page.scene.geometry = {
                    width: MmTurnPx(page.scene.geometry.width),
                    height: MmTurnPx(page.scene.geometry.height),
                    x: MmTurnPx(page.scene.geometry.x),
                    y: MmTurnPx(page.scene.geometry.y)
                };
                page.scene.imagebox.geometry = {
                    width: MmTurnPx(page.scene.imagebox.geometry.width),
                    height: MmTurnPx(page.scene.imagebox.geometry.height),
                    x: MmTurnPx(page.scene.imagebox.geometry.x),
                    y: MmTurnPx(page.scene.imagebox.geometry.y)
                };
                if (page.scene.resource) {
                    getBackgroundSource($http, page.scene.resource, page.scene.geometry.width, page.scene.geometry.height, document.pages.page[index].scene, $scope);
                }
                page.custom.width = page.scene.geometry.width;
                page.custom.height = page.scene.geometry.height;
            } else {
                page.custom.width = page.mediabox.width;
                page.custom.height = page.mediabox.height;
            }
            page.custom.thumbnailScale = 300 / page.custom.width;
            callbackRatio(page,index)
            //条形码
            if (page.barcode) {
                page.barcode.geometry = {
                    width: MmTurnPx(page.barcode.geometry.width),
                    height: MmTurnPx(page.barcode.geometry.height),
                    x: MmTurnPx(page.barcode.geometry.x),
                    y: MmTurnPx(page.barcode.geometry.y)
                };
            }
            if (page.qrcode) {
                page.qrcode.geometry = {
                    width: MmTurnPx(page.qrcode.geometry.width),
                    height: MmTurnPx(page.qrcode.geometry.height),
                    x: MmTurnPx(page.qrcode.geometry.x),
                    y: MmTurnPx(page.qrcode.geometry.y)
                };
            }
            //背景
            if (page.background && page.background.resource &&page.background.resource.identifier) {
                getBackgroundSource($http, page.background.resource, page.mediabox.width, page.mediabox.height, document.pages.page[index].background, $scope);
                page.background.offsetx = MmTurnPx(page.background.offsetx);
                page.background.offsety = MmTurnPx(page.background.offsety);
                page.background.type = 'Pic'
            }
            if(!page.levels.level){
                page.levels.level=[];
            }
            angular.forEach(page.levels.level, function (level, i) {
                (function (level, i) {
                    if (level.code && level.code.geometry) {
                        level.code.lock = true;
                        level.code.lock = true;
                        level.code.geometry = {
                            width: MmTurnPx(level.code.geometry.width),
                            height: MmTurnPx(level.code.geometry.height),
                            x: MmTurnPx(level.code.geometry.x),
                            y: MmTurnPx(level.code.geometry.y)
                        };
                    }
                    if (level.imageart && level.imageart.geometry) {
                        level.imageart.levelI = i;
                        level.imageart.pageI = index;
                        level.imageart.offsetx = MmTurnPx(level.imageart.offsetx);
                        level.imageart.offsety = MmTurnPx(level.imageart.offsety);
                        level.imageart.geometry = {
                            width: MmTurnPx(level.imageart.geometry.width),
                            height: MmTurnPx(level.imageart.geometry.height),
                            x: MmTurnPx(level.imageart.geometry.x),
                            y: MmTurnPx(level.imageart.geometry.y)
                        };
                        level.imageart.rotation.angle = -level.imageart.rotation.angle;
                        if (level.imageart.resource.identifier) {
                            getBackgroundSource($http, level.imageart.resource, level.imageart.geometry.width, level.imageart.geometry.height, document.pages.page[index].levels.level[i].imageart, $scope);
                        }
                    }
                    if (level.imagebox&&level.imagebox.geometry) {
                        level.imagebox.transparency = isNaN(level.imagebox.transparency) ? 1 : level.imagebox.transparency;
                        level.imagebox.geometry = {
                            width: MmTurnPx(level.imagebox.geometry.width),
                            height: MmTurnPx(level.imagebox.geometry.height),
                            x: MmTurnPx(level.imagebox.geometry.x),
                            y: MmTurnPx(level.imagebox.geometry.y)
                        };
                        // level.imagebox.rotation.angle = -level.imagebox.rotation.angle;
                        if (level.imagebox.image) {
                            level.imagebox.image.offsetx = MmTurnPx(level.imagebox.image.offsetx);
                            level.imagebox.image.offsety = MmTurnPx(level.imagebox.image.offsety);
                            if (level.imagebox.image.resource && level.imagebox.image.resource.identifier) {
                                getBackgroundSource1(level.imagebox.image.resource, level.imagebox.geometry.width, level.imagebox.geometry.height, document.pages.page[index].levels.level[i].imagebox.image, $scope,
                                    function () {})
                            }
                        }
                    }
                    if (level.textbox && level.textbox.geometry) {
                        level.textbox.geometry = {
                            width: MmTurnPx(level.textbox.geometry.width),
                            height: MmTurnPx(level.textbox.geometry.height),
                            x: MmTurnPx(level.textbox.geometry.x),
                            y: MmTurnPx(level.textbox.geometry.y)
                        };
                        level.textbox.rotation.angle = -level.textbox.rotation.angle;
                        if (level.textbox.fontuuid) {
                            //下载字体
                            getFont(level.textbox,FontManagement);
                        }
                        if (level.textbox.style.bold && level.textbox.style.italic) {
                            level.textbox.styles = 'BOLD_ITALIC';
                        } else if (level.textbox.style.bold) {
                            level.textbox.styles = 'BOLD';
                        } else if (level.textbox.style.italic) {
                            level.textbox.styles = 'ITALIC';
                        } else {
                            level.textbox.styles = 'NORMAL';
                        }
                    }
                    if (level.textdocument && level.textdocument.geometry) {
                        var textdocument = angular.copy(level.textdocument);
                        level.textdocument = {
                            background: textdocument.background,
                            geometry: {
                                width: MmTurnPx(textdocument.geometry.width),
                                height: MmTurnPx(textdocument.geometry.height),
                                x: MmTurnPx(textdocument.geometry.x),
                                y: MmTurnPx(textdocument.geometry.y)
                            },
                            rotation: {
                                angle: textdocument.rotation.angle!= null? textdocument.rotation.angle : 0
                            },
                            current: 'textdocument',
                            levels: angular.copy(textdocument.levels),
                            lock: true,
                            transparency: textdocument.transparency,
                            movable: textdocument.movable,
                            useradded: textdocument.useradded,
                            editable: textdocument.editable,
                            scale: textdocument.geometry.width / page.mediabox.width,
                            levelI:i,
                            pageI: index
                        }

                        var backg = textdocument.background;
                        var pageW = textdocument.geometry.width,
                            pageH = textdocument.geometry.height;
                        if (backg.resource.identifier) {
                            getBackgroundSource($http, backg.resource, pageW, pageH, document.pages.page[index].levels.level[i].textdocument.background, $scope);
                            backg.offsetx = MmTurnPx(backg.offsetx);
                            backg.offsety = MmTurnPx(backg.offsety);
                        }

                        angular.forEach(textdocument.levels.level, function (lev, l) {
                            if (lev.imageart && lev.imageart.geometry) {
                                var art = lev.imageart;
                                $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                                    level.textdocument.levels.level[l].imageart = {
                                        geometry: {
                                            width: MmTurnPx(art.geometry.width),
                                            height: MmTurnPx(art.geometry.height),
                                            x: MmTurnPx(art.geometry.x),
                                            y: MmTurnPx(art.geometry.y)
                                        },
                                        rotation: angular.copy(art.rotation),
                                        type: art.type,
                                        resource: {
                                            identifier: art.resource.identifier,
                                            provider: art.resource.provider,
                                            width: data.width,
                                            height: data.height
                                        },
                                        scale: art.scale,
                                        offsetx: MmTurnPx(art.offsetx),
                                        offsety: MmTurnPx(art.offsety),
                                        current: 'imageart',
                                        lock: false,
                                        transparency: art.transparency,
                                        onlyshow: art.onlyshow,
                                        movable: art.movable,
                                        useradded: art.useradded,
                                        editable: art.editable,
                                        levelI:i,
                                        pageI: index
                                    }
                                    level.textdocument.levels.level[l].imageart.rotation.angle = -level.textdocument.levels.level[l].imageart.rotation.angle;
                                }).error(function (error) {
                                });
                            }
                            if (lev.textbox && lev.textbox.geometry) {
                                var text = lev.textbox;
                                level.textdocument.levels.level[l].textbox = {
                                    geometry: {
                                        width: MmTurnPx(text.geometry.width),
                                        height: MmTurnPx(text.geometry.height),
                                        x: MmTurnPx(text.geometry.x),
                                        y: MmTurnPx(text.geometry.y)
                                    },
                                    identifier: text.identifier,
                                    rotation: angular.copy(text.rotation),
                                    angle: angular.copy(text.angle),
                                    status: angular.copy(text.status),
                                    style: angular.copy(text.style),
                                    type: angular.copy(text.type),
                                    text: angular.copy(text.text),
                                    fontStyle: [{
                                        style: '',
                                        path: text.identifier
                                    }],
                                    current: 'textbox',
                                    lock: true,
                                    transparency: text.transparency,
                                    fontuuid: text.fontuuid,
                                    version: text.version,
                                    levelI:i,
                                    pageI: index
                                }
                                level.textdocument.levels.level[l].textbox.rotation.angle = -level.textdocument.levels.level[l].textbox.rotation.angle;
                                getFont(text,FontManagement)
                            }
                        })
                    }
                    if (level.calMonthBox && level.calMonthBox.geometry) {
                        var calMonthBox = angular.copy(level.calMonthBox);
                        level.calMonthBox.geometry = {
                            width: MmTurnPx(calMonthBox.geometry.width),
                            height: MmTurnPx(calMonthBox.geometry.height),
                            x: MmTurnPx(calMonthBox.geometry.x),
                            y: MmTurnPx(calMonthBox.geometry.y),
                        }
                        level.calMonthBox.sampleYear = getDay('year',document.config.startYear,document.config.startMonth,page.config.monthIndex);
                        level.calMonthBox.sampleMonth = getDay('month',document.config.startYear,document.config.startMonth,page.config.monthIndex);
                    }
                    if (level.calTitleBox && level.calTitleBox.geometry) {
                        var calTitleBox = angular.copy(level.calTitleBox);
                        level.calTitleBox.geometry = {
                            width: MmTurnPx(calTitleBox.geometry.width),
                            height: MmTurnPx(calTitleBox.geometry.height),
                            x: MmTurnPx(calTitleBox.geometry.x),
                            y: MmTurnPx(calTitleBox.geometry.y),
                        }
                        level.calTitleBox.sampleYear = getDay('year',document.config.startYear,document.config.startMonth,page.config.monthIndex);
                        level.calTitleBox.sampleMonth = getDay('month',document.config.startYear,document.config.startMonth,page.config.monthIndex);
                    }
                })(level, i)
            })
        })(page, index)
    })
    setTimeout(function () {
        callback.call(this,document);
        $scope.$apply();
    },1000)
}
//裁切区域
function pageTrimbox(page, text) {
    if(page && page.custom){
        page.mediabox1 = angular.copy(page.mediabox);
        page.custom.width = page.trimbox.width;
        page.custom.height = page.trimbox.height;
        page.mediabox.width = page.trimbox.width;
        page.mediabox.height = page.trimbox.height;
        if (page.background && page.background.resource &&page.background.resource.identifier) {
            page.background.offsetx = page.background.offsetx-page.trimbox.x;
            page.background.offsety = page.background.offsety-page.trimbox.y;
        }
        if (page.barcode) {
            page.barcode.geometry.x = page.barcode.geometry.x-page.trimbox.x;
            page.barcode.geometry.y = page.barcode.geometry.y-page.trimbox.y;
        }
        if (page.qrcode) {
            page.qrcode.geometry.x = page.qrcode.geometry.x-page.trimbox.x;
            page.qrcode.geometry.y = page.qrcode.geometry.y-page.trimbox.y;
        }
        angular.forEach(page.levels.level, function (level, i) {
            if (level.imageart) {
                level.imageart.geometry.x = level.imageart.geometry.x-page.trimbox.x;
                level.imageart.geometry.y = level.imageart.geometry.y-page.trimbox.y;
            }
            if(level.imagebox){
                level.imagebox.geometry.x = level.imagebox.geometry.x-page.trimbox.x;
                level.imagebox.geometry.y = level.imagebox.geometry.y-page.trimbox.y;
            }
            if (level.textbox) {
                if (!(level.textbox.text == '' || level.textbox.text == null || level.textbox.text == text || level.textbox.text == '双击输入文字') && (level.textbox.geometry.width != 0 && level.textbox.geometry.height != 0)) {
                    level.textbox.geometry.x = level.textbox.geometry.x-page.trimbox.x;
                    level.textbox.geometry.y = level.textbox.geometry.y-page.trimbox.y;
                }else{
                    level.textbox = null;
                }
            }
            if (level.textdocument) {
                level.textdocument.geometry.x = level.textdocument.geometry.x-page.trimbox.x;
                level.textdocument.geometry.y = level.textdocument.geometry.y-page.trimbox.y;
            }
            if (level.code) {
                level.code.geometry.x = level.code.geometry.x-page.trimbox.x;
                level.code.geometry.y = level.code.geometry.y-page.trimbox.y;
            }
            if (level.calTitleBox) {
                level.calTitleBox.geometry.x = level.calTitleBox.geometry.x-page.trimbox.x;
                level.calTitleBox.geometry.y = level.calTitleBox.geometry.y-page.trimbox.y;
            }
            if (level.calMonthBox) {
                level.calMonthBox.geometry.x = level.calMonthBox.geometry.x-page.trimbox.x;
                level.calMonthBox.geometry.y = level.calMonthBox.geometry.y-page.trimbox.y;
            }
        })
    }
}
function getBackgroundSource($http, res, pageW, pageH, item, $scope, callback) {
    var newImg = new Image();
    newImg.crossOrigin = "Anonymous";
    newImg.src = res.identifier + "?imageMogr2/thumbnail/10x10";
    newImg.onload = function () {
        var bgImgW = pageW;
        var bgImgH = pageH;
        var bgImgR = bgImgW/bgImgH;
        var bgImgR1 = newImg.width/newImg.height;
        item.width = newImg.width;
        item.height = newImg.height;
        if(bgImgR >= bgImgR1){
            item.resource.adaptation = 'Width';
            item.adaptation = 'Width';
        }else {
            item.resource.adaptation = 'Height';
            item.adaptation = 'Height';
        }
        if(callback){
            callback();
        }
        $scope.$apply();
    };
}
function getBackgroundSource1(res, pageW, pageH, item, $scope,callback) {
    if(!item.height && !item.width){
        var newImg = new Image();
        newImg.crossOrigin = "Anonymous";
        newImg.src = res.identifier + '?imageMogr2/strip/rotate/' + item.angle + '/thumbnail/!40p';
        newImg.onload = function () {
            getBackgroundSource1Cb(res, pageW, pageH, item, $scope,newImg, function () {
                if(callback){callback()};
                $scope.$apply();
            })
        };
    }else{
        var newImg = {
            width: angular.copy(item.width),
            height: angular.copy(item.height),
        }
        getBackgroundSource1Cb(res, pageW, pageH, item, $scope,newImg, callback)
    }
}
function getBackgroundSource1Cb(res, pageW, pageH, item, $scope,newImg, callback) {
    var imgR = pageW / pageH;
    var imgR1 = newImg.width / newImg.height;
    if (imgR >= imgR1) {
        item.resource.adaptation = 'Height';
        item.width = pageW;
        item.height = pageW / newImg.width * newImg.height;
    } else {
        item.resource.adaptation = 'Width';
        item.width = pageH / newImg.height * newImg.width;
        item.height = pageH;
    }
    if(callback){
        callback()
    }
}
var fontuuids = [];
function getFont(item,FontManagement, callback) {
    if(!fontuuids){
        fontuuids = [];
    }
    if(fontuuids.indexOf(item.fontuuid) == -1){
        fontuuids.push(item.fontuuid);
        FontManagement.getCss({uuid: item.fontuuid}, function (res) {
            var fontnode = res.message;
            var style = document.createElement("style");
            style.type = 'text/css';
            try {
                style.appendChild(document.createTextNode(fontnode));
            } catch (ex) {
                style.stylesheet.cssText = fontnode;
            }
            var Head = document.getElementsByTagName("head")[0];
            Head.appendChild(style);
            document.fonts.ready.then(function () {});
            if (callback) {
                callback();
            }
        });
    }else{
        if (callback) {
            callback();
        }
    }
}

function checkFloatNum(str){
    str = Number(str);
    var reg_zs = /^[0-9]\d*(\.\d+)?$/i;
    if (!reg_zs.test(str)) {
        return false;
    }
    return true;
}


function compare(property, attr) {
    return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        if (attr) {
            return value1 - value2;
        } else {
            return value2 - value1;
        }
    }
}

/**
 * 校验手机号
 */
function checkPhone(phone){
    var reg = /^1[3456789]\d{9}$/;
    return reg.test(phone);
}
/**
 * 校验账号
 */
function checkUserName(name){
    var reg = /^[_'.@A-Za-z0-9-]*$/;
    return reg.test(name);
}


var adsorbNumber = 6;
function adsorbAngle(angle) {
    if(-adsorbNumber < angle && angle < adsorbNumber){angle = 0}
    else if(90-adsorbNumber < angle && angle < 90+adsorbNumber){angle = 90}
    else if(180-adsorbNumber < angle && angle < 180+adsorbNumber){angle = 180}
    else if(270-adsorbNumber < angle && angle < 270+adsorbNumber){angle = 270}
    else if(360-adsorbNumber < angle && angle < 360+adsorbNumber){angle = 360}
    return angle
}

/**
 * 获取年月
 * */
function getDay(item,year,month,monthIndex) {
    var _ret = "";
    var month = month<10?"0"+month:month;
    var currentDate = new Date(year + "-" + month);
    currentDate.setMonth(currentDate.getMonth() + monthIndex-1);
    if(item=='year'){
        _ret = currentDate.getFullYear()
    }
    if(item=='month'){
        _ret = (currentDate.getMonth() + 1)
    }
    return _ret
}
/**
 * 创建日历和标题
 * */
function getTitleWidget(seq,titleWidget,unitConversion,x,y) {
    var _titleWidget = angular.copy(titleWidget);
    
    var _level = {
        calTitleBox:{
            geometry: {
                width:unitConversion?_titleWidget.width : PxTurnMm(_titleWidget.width),
                height:unitConversion?_titleWidget.height : PxTurnMm(_titleWidget.height),
                x:x?(unitConversion?x:PxTurnMm(x)):0,
                y:y?(unitConversion?y:PxTurnMm(y)):0,
            },
            rotation:{
                angle:0
            },
            name:_titleWidget.name,
            previewUrl:_titleWidget.previewUrl,
            settings:{
                border:_titleWidget.settings.border,
                items:{
                    item:_titleWidget.settings.items
                }
            },
            layout:{
                geometry: {
                    width:_titleWidget.layout.width,
                    height:_titleWidget.layout.height,
                    x:0,
                    y:0,
                },
                previewUrl:_titleWidget.layout.previewUrl,
                type:_titleWidget.layout.type,
                items:{
                    item:_titleWidget.layout.items,
                }
            },
            current: 'calTitleBox',
        },
        seq:seq
    }
    _level.calTitleBox.layout.items.item.forEach(function (item) {
        item.geometry = {
            width:item.w,
            height:item.h,
            x:item.x,
            y:item.y,
        }
    })
    _level.calTitleBox.settings.items.item.forEach(function (item) {
        item.geometry = {
            width:item.w,
            height:item.h,
            x:item.x,
            y:item.y,
        }
    })
    return _level
}
function getMonthWidget(seq,monthWidget,unitConversion,x,y) {
    var _monthWidget = angular.copy(monthWidget);
    var _level = {
        calMonthBox:{
            geometry: {
                width:unitConversion?_monthWidget.width : PxTurnMm(_monthWidget.width),
                height:unitConversion?_monthWidget.height : PxTurnMm(_monthWidget.height),
                x:x?(unitConversion?x:PxTurnMm(x)):0,
                y:y?(unitConversion?y:PxTurnMm(y)):0,
            },
            rotation:{
                angle:0
            },
            weekConfig:_monthWidget.weekConfig,
            weekStyles:{item:_monthWidget.weekStyles},
            weekLayout:{
                geometry: {
                    width:_monthWidget.weekLayout.width,
                    height:_monthWidget.weekLayout.height,
                    x:0,
                    y:0,
                },
                items:{
                    item:_monthWidget.weekLayout.items
                },
                type:_monthWidget.weekLayout.type,
                previewUrl:_monthWidget.weekLayout.previewUrl
            },
            dateConfig:_monthWidget.dateConfig,
            dateStyles:{item:_monthWidget.dateStyles},
            dateLayout:{
                geometry: {
                    width:_monthWidget.dateLayout.width,
                    height:_monthWidget.dateLayout.height,
                    x:0,
                    y:0,
                },
                items:{
                    item:_monthWidget.dateLayout.items
                },
                type:_monthWidget.dateLayout.type,
                previewUrl:_monthWidget.dateLayout.previewUrl
            },
            displayType:_monthWidget.displayType,
            backgroundColor:_monthWidget.backgroundColor,
            showOthers:_monthWidget.showOthers,
            startOfWeek:_monthWidget.startOfWeek,
            current: 'calMonthBox',
        },
        seq:seq
    }
    _level.calMonthBox.weekLayout.items.item.forEach(function (weekLayout) {
        weekLayout.geometry = {
            width:weekLayout.w,
            height:weekLayout.h,
            x:weekLayout.x,
            y:weekLayout.y,
        }
    })
    _level.calMonthBox.dateLayout.items.item.forEach(function (dateLayout) {
        dateLayout.geometry = {
            width:dateLayout.w,
            height:dateLayout.h,
            x:dateLayout.x,
            y:dateLayout.y,
        }
    })
    _level.calMonthBox.weekStyles.item.forEach(function (weekStyle) {
        var _items = angular.copy(weekStyle.items);
        _items.forEach(function (_item) {
            _item.geometry = {
                width:_item.w,
                height:_item.h,
                x:_item.x,
                y:_item.y,
            }
        })
        weekStyle.items = {
            item:_items
        }
    })
    _level.calMonthBox.dateStyles.item.forEach(function (dateStyle) {
        var _items = angular.copy(dateStyle.items);
        _items.forEach(function (_item) {
            _item.geometry = {
                width:_item.w,
                height:_item.h,
                x:_item.x,
                y:_item.y,
            }
        })
        dateStyle.items = {
            item:_items
        }
    })
    return _level
}
//防抖
var _antiShake = null;
function antiShake(callback,time) {
    var _time = time || 500;
    if (_antiShake !== null) {
        clearTimeout(_antiShake)
    }
    _antiShake = setTimeout(function () {
        callback()
    },_time)
}

//判断月份是否连续，并列出不连续的月份
function findMissingNumbers(numbers) {
    var missingNumbers = [];
    var max = Math.max.apply(null, numbers);
    var min = Math.min.apply(null, numbers);
    for (var i = min; i <= max; i++) {
        if (!numbers.includes(i)) {
            missingNumbers.push(i);
        }
    }
    return missingNumbers;
}
//获取日历一行几列
function displayType(item) {
    switch (item) {
        case 'C7':
            return 7;
        case 'C14':
            return 14;
        case 'C21':
            return 21;
        default:
            return 7;
    }
}


// 监听dom元素加载完成事件
function getDomOnload(scope, id, callback) {
    var monitorNum  = 0;
    // 如果monitorNum始终为0，则判断dom没有渲染
    setTimeout(function () {
        if (monitorNum == 0) {
            if (typeof callback === 'function') callback();
            observer.disconnect();
            scope.$apply();
        }
    },500)
    var targetDiv = document.querySelector('#'+id);// 获取要监听的 div 元素
    var config = { childList: true, subtree: true};// 配置 MutationObserver，监听子节点的变化
    var observer = new MutationObserver(function(mutationsList, observer) {// 创建一个 MutationObserver 实例
        // 遍历所有的 mutation
        for (var i = 0; i < mutationsList.length; i++) {
            var mutation = mutationsList[i];
            // 判断 mutation 是否是子节点的变化
            if (mutation.type === 'childList') {
                // 判断是否是更新状态的变化
                if (mutation.addedNodes.length) {
                    monitorNum++;
                    takeScreenshot();
                }
            }
        }
    });
    observer.observe(targetDiv, config);
    // 执行截图事件的函数
    var timer = null;
    function takeScreenshot() {
        if (timer !== null) {
            clearTimeout(timer)
        }
        timer = setTimeout(function () {
            if (typeof callback === 'function') callback();
            observer.disconnect();
            scope.$apply();
        },1000)
    }
}

// 圆角切换  固定/百分比
function switchToRoundedMode(imagebox, borderRadiusAllbtn) {
    var radiusW = Math.min(imagebox.geometry.width, imagebox.geometry.height);
    radiusW = radiusW + Number(imagebox.border.lineWidth);
    if (imagebox.border.radius.filletType == 'Proportion') {// 固定值转百分比
        if (borderRadiusAllbtn == true) {
            var all = (imagebox.border.radius.all / radiusW * 100) > 100 ? 100 : Math.round(imagebox.border.radius.all / radiusW * 100);
            imagebox.border.radius.all = all;
            imagebox.border.radius.lt = all;
            imagebox.border.radius.rt = all;
            imagebox.border.radius.rb = all;
            imagebox.border.radius.lb = all;
        } else {
            var scaleDown = scaleDownTo100((imagebox.border.radius.lt / radiusW * 100), (imagebox.border.radius.rt / radiusW * 100), (imagebox.border.radius.rb / radiusW * 100), (imagebox.border.radius.lb / radiusW * 100))
            imagebox.border.radius.lt = scaleDown[0];
            imagebox.border.radius.rt = scaleDown[1];
            imagebox.border.radius.rb = scaleDown[2];
            imagebox.border.radius.lb = scaleDown[3];
        }
    } else {//百分比转固定值
        if (borderRadiusAllbtn == true) {
            var all = Math.round(imagebox.border.radius.all / 100 * radiusW);
            imagebox.border.radius.all = all;
            imagebox.border.radius.lt = all;
            imagebox.border.radius.rt = all;
            imagebox.border.radius.rb = all;
            imagebox.border.radius.lb = all;
        } else {
            imagebox.border.radius.lt = Math.round(imagebox.border.radius.lt / 100 * radiusW);
            imagebox.border.radius.rt = Math.round(imagebox.border.radius.rt / 100 * radiusW);
            imagebox.border.radius.rb = Math.round(imagebox.border.radius.rb / 100 * radiusW);
            imagebox.border.radius.lb = Math.round(imagebox.border.radius.lb / 100 * radiusW);
        }
    }


    // 等比列缩小到100以下
    function scaleDownTo100(value1, value2, value3, value4) {
        var maxValue = Math.max(value1, value2, value3, value4);
        if (maxValue < 100) {
            return [Math.round(value1), Math.round(value2), Math.round(value3), Math.round(value4)];
        }
        var scaleRatio = 100 / maxValue;

        // 缩小数值
        var scaledValue1 = Math.round(value1 * scaleRatio);
        var scaledValue2 = Math.round(value2 * scaleRatio);
        var scaledValue3 = Math.round(value3 * scaleRatio);
        var scaledValue4 = Math.round(value4 * scaleRatio);

        // 返回缩小后的数值
        return [scaledValue1, scaledValue2, scaledValue3, scaledValue4];
    }
}
